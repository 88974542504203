@media (min-width: 640px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .home-heading {
    font-size: 3.1rem;
    padding: 0 20px;
  }
  .home-paragraph {
    padding: 0 30px;
  }

  .submit-contact {
    font-size: 15px;
    padding: 9px 15px;
  }
}

@media screen and (max-width: 650px) {
  .contact-way {
    grid-template-columns: repeat(1, minmax(0, 400px));
  }
}

@media screen and (max-width: 590px) {
  .home-heading {
    font-size: 2.5rem;
  }

  .about-image {
    max-width: 288px;
    max-height: 370px;
  }
}

@media screen and (max-width: 380px) {
  .faq,
  .faq-p {
    font-size: 12px;
  }

  .typing-text-home {
    font-size: 20px;
  }
}

@media screen and (max-width: 350px) {
  .about-image {
    max-width: 240px;
    max-height: 320px;
  }
}

@media screen and (max-width: 340px) {
  .faq,
  .faq-p {
    font-size: 11px;
  }

  .resume-btn {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 315px) {
  .faq,
  .faq-p {
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .alert-box {
    height: 50px;
    font-size: 13px;
  }
  .icon-alert-box {
    padding: 1px 0;
  }

  /* .section-heading {
    font-size: 25px;
  } */
}

@media screen and (max-width: 490px) {
  .home-heading {
    font-size: 2rem;
  }
  .about-heading {
    font-size: 28px;
  }

  .section-heading {
    font-size: 23px;
  }
}

@media screen and (max-width: 1023px) {
  .about-heading {
    text-align: center;
  }
  .about-para {
    text-align: center;
  }
}

@media screen and (max-width: 1029px) {
  .skill-cat {
    font-size: 1rem;
  }
}

@media screen and (max-width: 880px) {
  .main-skill-cover {
    grid-template-columns: repeat(2, minmax(0, 300px));
  }

  .contact-info-val {
    font-size: 0.87rem;
  }
  
  .contact-placeholder {
    font-size: 13.5px;
    font-weight: 500;
    color: rgb(29, 39, 58);
  }
  
  .contact-input {
    font-size: 13px;
    font-weight: 500;
  }
}

@media screen and (max-width: 575px) {
  .main-skill-cover {
    grid-template-columns: repeat(1, minmax(0, 300px));
  }

  /* .about-image {
    width: 350px;
    height: 450px;
  } */
}
