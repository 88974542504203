/* Importing Google Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  hyphens: auto;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

.about-image {
  width: 380px;
  height: 510px;
}

#header {
  transition: background 0.5s ease, box-shadow 0.5s ease;
}

.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.about-section {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;
}

.about-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.progress-bar {
  transition: all 3s;
}

.skill-box {
  margin-top: 100px;
  transition: all 0.5s ease-in-out;
}

.main-skill-cover {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 350px));
  justify-content: center;
}

.hidden-answer {
  display: none;
}

.edu-box {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.edu-box:nth-child(1),
.edu-box:nth-child(2) {
  opacity: 1;
}

.edu-des {
  font-size: 14px;
  text-align: justify;
}

.cursor-pointer:focus {
  outline: none;
  box-shadow: none;
}

.faq {
  color: rgb(29, 39, 58);
}

.faq-box {
  transition: max-height 0.5s ease;
  overflow: hidden;
  max-height: 45px;
}

.expanded {
  max-height: 500px;
}

.icon-alert-box {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  text-align: center;
}

.contact-info-val {
  font-size: 0.9rem;
  color: rgb(29, 39, 58);
}

.contact-placeholder {
  font-size: 15px;
  font-weight: 500;
  color: rgb(29, 39, 58);
}

.contact-input {
  font-size: 14px;
  font-weight: 500;
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-fade-in-down {
  animation: fade-in-down 1s ease-out;
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
